import React, { createContext, useContext, useEffect, useState } from "react";

type Theme = "light" | "dark";

interface ThemeContextType {
  theme: Theme;
  toggleTheme: () => void;
  isEmbedded: boolean;
  parentDomain: string;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

/**
 * Detecta si la aplicación está siendo embebida en un iframe y obtiene información del dominio
 */
interface IframeInfo {
  isEmbedded: boolean;
  parentDomain: string | null;
  shouldForceLightMode: boolean;
}

const getIframeInfo = (): IframeInfo => {
  let isEmbedded = false;
  let parentDomain = null;
  
  try {
    // Comprobar si estamos en un iframe
    isEmbedded = window !== window.parent;
    
    // Obtener el dominio del referrer
    if (isEmbedded && document.referrer) {
      try {
        const referrerUrl = new URL(document.referrer);
        parentDomain = referrerUrl.hostname;
      } catch (e) {
        console.error('Error al parsear el referrer:', e);
      }
    }
  } catch (e) {
    // Si hay un error de seguridad al intentar acceder a window.parent,
    // es probable que estemos en un iframe con política de mismo origen
    isEmbedded = true;
  }
  
  // Determinar si debemos forzar el modo claro
  // Solo forzar modo claro si estamos embebidos en un dominio diferente a results.events
  const shouldForceLightMode = isEmbedded && 
    (parentDomain !== null && !parentDomain.includes('results.events'));
    
  return {
    isEmbedded,
    parentDomain,
    shouldForceLightMode
  };
};

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Detectar si estamos en un iframe y obtener información del dominio
  const { isEmbedded, parentDomain, shouldForceLightMode } = getIframeInfo();
  
  const [theme, setTheme] = useState<Theme>(() => {
    // Si estamos en un iframe en un dominio diferente a results.events, forzar modo claro
    if (shouldForceLightMode) {
      return "light";
    }
    
    // Check localStorage first
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark" || savedTheme === "light") {
      return savedTheme;
    }

    // Check system preference
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      return "dark";
    }

    // Check time of day (dark theme between 7PM and 7AM)
    const hour = new Date().getHours();
    if (hour >= 19 || hour < 7) {
      return "dark";
    }

    return "dark";
  });

  useEffect(() => {
    // Si estamos en un iframe en un dominio diferente a results.events, forzar modo claro
    if (shouldForceLightMode) {
      setTheme("light");
    } else {
      // Save theme preference to localStorage
      localStorage.setItem("theme", theme);
    }

    // Update document class for Tailwind dark mode
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme, isEmbedded, shouldForceLightMode]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ 
      theme, 
      toggleTheme, 
      isEmbedded,
      // Añadir información del dominio al contexto
      parentDomain: parentDomain || ''
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};
