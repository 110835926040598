import React from "react";

interface ProgressBarProps {
  progress: number;
  color?: "default" | "blue" | "green" | "red" | "yellow";
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  color = "default",
}) => {
  // Mapeo de colores para la barra de progreso
  const colorClasses = {
    default: "bg-gray-400 dark:bg-gray-400",
    blue: "bg-blue-400 dark:bg-blue-400",
    green: "bg-green-400 dark:bg-green-400",
    red: "bg-red-400 dark:bg-red-400",
    yellow: "bg-yellow-400 dark:bg-yellow-400",
  };

  return (
    <div className="w-full h-2 bg-gray-200 dark:bg-gray-600 rounded-md">
      <div
        className={`h-full rounded-md transition-all ${colorClasses[color]}`}
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};
