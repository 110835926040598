import React, { useState, useCallback, useEffect, useRef } from "react";
import { SearchOutline, LeftOutline } from "antd-mobile-icons";
import { Button, Popup, List, Tag } from "antd-mobile";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { searchParticipantResultsRequest } from "@/services/qr/participant.service";
import { ParticipantSearchDto } from "@/services/qr/dto/participant/participant.dto";
import GenderBadge from "./GenderBadge";
import { GenderEnum } from "@/enums/gender.enum";
import SearchInput from "./SearchInput";
import LoadingScreen from "@/components/LoadingScreen";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

const SearchButton: React.FC = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState<ParticipantSearchDto[]>([]);
  const [loading, setLoading] = useState(false);
  const [popupKey, setPopupKey] = useState(0);
  const resultsContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { prefix } = useParams<{ prefix?: string }>();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * Bloquea el scroll del body cuando el popup está abierto
   * usando body-scroll-lock para compatibilidad con iOS
   */
  useEffect(() => {
    if (showPopup && resultsContainerRef.current) {
      // Bloquear scroll del body pero permitir scroll en el contenedor de resultados
      disableBodyScroll(resultsContainerRef.current, {
        reserveScrollBarGap: true,
        allowTouchMove: (el) => {
          // Permitir scroll en el contenedor de resultados y sus hijos
          return resultsContainerRef.current?.contains(el) || false;
        },
      });
    }

    // Limpiar al cerrar o desmontar
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [showPopup]);

  /**
   * Obtiene el prefijo actual (si existe) a partir de la URL
   */
  const getCurrentPrefix = useCallback(() => {
    if (prefix) return prefix;
    const pathParts = location.pathname.split("/").filter(Boolean);
    if (pathParts.length > 0) {
      const searchIndex = pathParts.indexOf("buscar");
      if (searchIndex > 0) {
        return pathParts[searchIndex - 1];
      }
      return pathParts[0];
    }
    return "";
  }, [prefix, location.pathname]);

  /**
   * Llama al servicio para buscar participantes
   */
  const performSearch = useCallback(
    async (query: string) => {
      const currentPrefix = getCurrentPrefix();
      if (!query || !currentPrefix) {
        setResults([]);
        return;
      }
      try {
        setLoading(true);
        const data = await searchParticipantResultsRequest(
          currentPrefix,
          query
        );
        setResults(data);
      } catch (error) {
        console.error("Error searching participants:", error);
        setResults([]);
      } finally {
        setLoading(false);
      }
    },
    [getCurrentPrefix]
  );

  /**
   * Handler del cambio en el input de búsqueda
   */
  const handleSearch = (value: string) => {
    setSearchValue(value);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (value.trim() !== "") {
      timeoutRef.current = setTimeout(() => performSearch(value), 400);
    } else {
      setResults([]);
    }
  };

  /**
   * Selecciona un participante de la lista
   */
  const handleSelectParticipant = (participant: ParticipantSearchDto) => {
    handleClosePopup();
    const searchPrefix = getCurrentPrefix();
    if (searchPrefix) {
      navigate(`/${searchPrefix}/${participant.number}`);
    }
  };

  /**
   * Abre el popup
   */
  const handleOpenPopup = () => {
    setPopupKey((prev) => prev + 1);
    setSearchValue("");
    setResults([]);
    setShowPopup(true);
  };

  /**
   * Cierra el popup
   */
  const handleClosePopup = () => {
    setShowPopup(false);
    setSearchValue("");
    setResults([]);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <>
      {/* Botón de búsqueda */}
      <Button
        onClick={handleOpenPopup}
        className="!px-2"
        fill="none"
        style={{ "--background-color": "transparent" }}
      >
        <SearchOutline
          className="text-gray-600 dark:text-gray-300"
          fontSize={24}
        />
      </Button>

      {/* Popup de búsqueda */}
      <Popup
        key={popupKey}
        visible={showPopup}
        onMaskClick={handleClosePopup}
        position="top"
        maskStyle={{
          background: "rgba(0, 0, 0, 0.75)",
        }}
        bodyStyle={{
          padding: 0,
          backgroundColor: "var(--adm-color-background)",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          maxHeight: "100%",
          overflow: "hidden",
        }}
        showCloseButton={false}
      >
        <div className="flex flex-col h-full min-h-0">
          {/* Header sticky (barra de búsqueda) */}
          <div className="bg-gray-100 dark:bg-gray-700 sticky top-0 z-10 border-b border-gray-200 dark:border-gray-800">
            <div className="flex items-center p-2">
              <Button fill="none" onClick={handleClosePopup} className="p-2">
                <LeftOutline className="text-2xl text-gray-600 dark:text-gray-300" />
              </Button>
              <div className="flex-1 mx-2">
                <SearchInput value={searchValue} onChange={handleSearch} />
              </div>
            </div>
          </div>

          {/* Contenedor de resultados con scroll */}
          <div
            ref={resultsContainerRef}
            className="flex-1 min-h-0 overflow-y-auto bg-white dark:bg-gray-900"
            style={{
              WebkitOverflowScrolling: "touch",
              overscrollBehavior: "contain",
            }}
          >
            {loading && <LoadingScreen />}

            {!loading && results.length === 0 && searchValue.trim() !== "" && (
              <div className="text-center py-10 text-gray-500 text-base">
                No se encontraron resultados
              </div>
            )}

            {!loading && results.length > 0 && (
              <List className="m-0 p-0">
                {results.map((participant) => (
                  <List.Item
                    key={participant.number}
                    onClick={() => handleSelectParticipant(participant)}
                    className="py-2"
                  >
                    <GenderBadge
                      gender={participant.gender as GenderEnum}
                      text={
                        <h2 className="text-xl w-full max-w-full flex items-center gap-3 dark:text-white">
                          <span className="truncate">{participant.name}</span>
                          <Tag className="dark:text-gray-800 shrink-0">
                            {participant.number}
                          </Tag>
                        </h2>
                      }
                    />
                  </List.Item>
                ))}
              </List>
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default SearchButton;
